.projectsDark{
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: 10%;
    margin-right: 10%;
    width: auto;
}

.projectsLight{
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: auto;
}

.projectsMain{
    padding: 0;
    margin: 0;
    padding-top: 30px;
    padding-bottom: 30px !important;
    height: auto;
}


h1 {
    margin-bottom: 10px;
    font-size: 4rem;
    line-height: 1.0625;
    font-weight: 600;
    letter-spacing: -.01em;
    text-transform: none;
}

.projectsParagraph {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 20px;
    line-height: 28px;
    font-weight: 300;
}

.content-projectsParagraphDark {
    margin-top: 20px;
    color: #fff;
    font-size: 25px;
    line-height: 26px;
}

.content-projectsParagraphLight {
    margin-top: 20px;
    color: black;
    font-size: 25px;
    line-height: 26px;
}

@media only screen and (min-width: 576px) and (max-width: 767.99px) {
    .projectsMain{
        padding: 0;
        margin: 0;
        padding-top: 30px;
        height: auto; 
    }

    .my-5{
        margin-top: 0rem !important;
        margin-bottom: 0rem !important;
    }

    h1 {
        margin-bottom: 10px;
        font-size: 3rem;
        line-height: 1.0625;
        font-weight: 600;
        letter-spacing: -.01em;
        text-transform: none;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.99px){

    .projectsMain{
        padding: 0;
        margin: 0;
        padding-top: 30px;
        height: auto;
    }

    .my-5{
        margin-top: 0rem !important;
        margin-bottom: 0rem !important;
    }

    h1 {
        margin-bottom: 10px;
        font-size: 3rem;
        line-height: 1.0625;
        font-weight: 600;
        letter-spacing: -.01em;
        text-transform: none;
    }

    .projectsDark{
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-left: 5%;
        margin-bottom: 25px;
        margin-right: 5%;
        width: auto !important;
    }
    
    .projectsLight{
        color: black;
        width: auto !important;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-left: 5%;
        margin-bottom: 25px;
        margin-right: 5%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199.99px) {

    .projectsMain{
        height: auto;
    }

    .my-5{
        margin-top: 0rem !important;
        margin-bottom: 0rem !important;
    }

    h1 {
        margin-bottom: 10px;
        font-size: 3.2rem;
        line-height: 1.0625;
        font-weight: 600;
        letter-spacing: -.01em;
        text-transform: none;
    }

    .projectsDark{
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-left: 5%;
        margin-bottom: 25px;
        margin-right: 5%;
        width: auto !important;
    }
    
    .projectsLight{
        color: black;
        width: auto !important;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-left: 5%;
        margin-bottom: 25px;
        margin-right: 5%;
    }
}

@media only screen and (min-width: 360px) and (max-width: 575.99px){
    .projectsMain{
        padding: 0;
        margin: 0;
        padding-top: 30px;
        height: auto;
    }

    .my-5{
        margin-top: 0rem !important;
        margin-bottom: 0rem !important;
    }

    h1 {
        font-size: 2.2rem;
    }

    .projectsDark{
        width: auto;
    }

    .projectsLight{
        width: auto;
    }
}