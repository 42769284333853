.aboutDark{
    color: white;
    margin: 10%;
    width: auto;
    height: auto;
}

.aboutLight{
    color: black;
    margin: 10%;
    width: auto;
    /* height: auto;    */
    /* max-width: 700px; */
}

.aboutImageDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.aboutImageDivImgTag{
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
}

h1{
    font-size: 4rem;
    line-height: 1.0625;
    font-weight: 600;
    letter-spacing: -.01em;
    text-transform: none;
}

.aboutMain{
    padding: 0;
    margin: 0;
    height: 750px;
}

.paragraph {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 20px;
    line-height: 28px;
    font-weight: 300;
}

.content-paragraphDark {
    margin-top: 25px;
    color: #fff;
    font-size: 18px;
    line-height: 26px;
    font-weight: 300;
}

.content-paragraphLight {
    margin-top: 25px;
    color: black;
    font-size: 18px;
    line-height: 26px;
    font-weight: 300;
}

.link, .link:hover{
    text-decoration: none;
    color: orange;
}

@media only screen and (min-width: 360px) and (max-width: 575.99px){
    .aboutMain{
        height: auto;
    }

    .aboutMain h1{
        font-size: 2.2rem;
        margin-bottom: 10%;
    }

    .paragraph {
        font-size: 1.3rem !important;
        line-height: 25px !important;
    }

    .aboutImageDiv{
        display: none;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767.99px) {
    
    .aboutMain{
        padding: 0;
        margin: 0;
        height: auto;
    }

    .aboutMain h1{
        font-size: 3rem !important;
    }

    .aboutImageDiv{
        display: none;
    }

    .content-paragraphLight {
        margin-top: 25px;
        color: black;
        font-size: 1.4rem;
        line-height: 30px;
        font-weight: 300;
    }

    .content-paragraphDark {
        margin-top: 25px;
        color: white;
        font-size: 1.4rem;
        line-height: 30px;
        font-weight: 300;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.99px){
    
    .aboutMain{
        height: auto;
    }
    .aboutImageDiv{
        display: none;
    }

    .aboutMain h1{
        font-size: 3rem !important;
    }

    .paragraph {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 30px;
        line-height: 28px;
        font-weight: 300;
        width: 768px;
    }
    
    .content-paragraphDark {
        margin-top: 25px;
        color: #ffffff;
        font-size: 25px !important;
        line-height: 30px !important;
        font-weight: 300;
        width: auto;
    }
    
    .content-paragraphLight {
        margin-top: 25px;
        color: black;
        font-size: 25px !important;
        line-height: 30px !important;
        font-weight: 300;
        width: 700px !important;
    }

    .aboutDark{
        color: white;
        margin: 10%;
        width: 700px;
    }
    
    .aboutLight{
        color: black;
        margin: 10%;
        width: 700px !important;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199.99px){
    .aboutMain{
        height: auto;
    }

    .aboutMain h1{
        font-size: 3.8rem !important;
    }

    .paragraph {
        margin-top: 0;
        margin-bottom: 10px;
        font-size: 30px !important;
        line-height: 42px !important;
        font-weight: 300;
    }

    .aboutImageDiv{
        display: none;
    }

    .aboutDark{
        color: white;
        margin: 10%;
        width: 700px;
    }
    
    .aboutLight{
        color: black;
        margin: 10%;
        width: 700px !important;
    }
}

@media only screen and (min-width: 1200px){
    .aboutMain{
        height: auto;
    }

    .aboutImageDiv{
        display: none;
    }

    .aboutLight{
        width: 1000px;
        margin-left: 10% !important;
        padding-left: 10%;
    }
    
    .aboutDark{
        width: 1000px;
        margin-left: 10% !important;
        padding-left: 10%;
    }

    .aboutMain h1{
        font-size: 4rem !important;
    }

    .content-paragraphLight{
        font-size: 1.75rem;
        line-height: 35px;
    }

    .content-paragraphDark{
        font-size: 1.75rem;
        line-height: 35px;
    }
}