.homeMain{
    padding: 0;
    margin: 0;
    height: auto;
}

.image{    
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    margin-right: 100px;
    padding-top: 5%;
}

.image2{
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
    width: 400px;
    border-radius: 200px;
    background-color: rgb(255, 255, 110, 1);
}

.introDark{
    color: white;
    justify-content: center;
    align-items: center;
    padding: 10% !important;
    width: auto;
    height: auto;   
    max-width: 700px;
}

.introLight{
    color: black;
    justify-content: center;
    align-items: center;
    padding: 10% !important;
    width: auto;
    height: auto;   
    max-width: 700px;
}

.introDark h3{
    font-weight: 300;
}

.introDark h1{
    margin-bottom: 10px;
    line-height: 1.0625;
    font-weight: 600;
    letter-spacing: -.01em;
    text-transform: none;
}

.introLight h3{
    font-weight: 300;
}

.introLight h1{
    margin-bottom: 10px;
    font-size: 4rem;
    line-height: 1.0625;
    font-weight: 600;
    letter-spacing: -.01em;
    text-transform: none;
}

.socialDiv{
    display: flex;
    padding-left: 10%;
    width: 400px;
    height: 50px;   
    justify-content: space-between;
    max-width: 400px;
}

.socialItem{
    width: 50px;
    max-width:50px ;
}

.socialItem img{
    width: 50px;
    height: 50px;
}

.link, .link:hover{
    text-decoration: none;
    color: orange;
}

@media only screen and (min-width: 360px) and (max-width: 575.99px){
    .homeMain{
        height: auto;
    }

    .introLight{
        padding-bottom: 2% !important;
    }

    .introLight h1{
        font-size: 2.2rem !important;
    }

    .introLight h3{
        font-size: 1.5rem !important;
    }

    .introDark h1{
        font-size: 2.2rem !important;
    }

    .introDark h3{  
        font-size: 1.5rem !important;
    }

    .homeMain .link{
        font-size: 1.5rem !important;
    }

    .socialDiv{
        max-width: 50%;
    }

    .socialItem{
        margin-left: 2% !important;
    }

    .socialItem img{
        width: 100%;
        height: 100%;
    }

    .image2{
        min-width: 150px !important;
        min-height: 150px !important;
        max-width: 250px;
        max-height: 250px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767.99px){

    .homeMain{
        height: auto;
    }

    .image{
        margin-top: 0 !important;
    }

    .introLight{
        margin-left: 2%; 
        margin-top: 2%;
        padding: 7% 4%!important;
    }

    .introLight h1{
        margin-top: 5px;
        font-size: 3rem !important;
    }

    .introDark h1{
        margin-top: 5px;
        font-size: 3rem !important;
    }
    
    .introDark{
        margin-left: 2%; 
        margin-top: 2%;
        padding: 7% 4%!important;
    }

    .socialDiv{
        width: 320px;
        max-width: 260px;
        height: 40px;
        padding-left: 5%;
    }

    .socialItem{
        width: 40px;
        max-width:40px ;
    }

    .socialItem img{
        width: 40px;
        height: 40px;
    }

    .image2{
        justify-content: center !important;
        align-items: center !important;
        text-align: center !important;
        height: 300px;
        width: 300px;
        border-radius: 300px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.99px){
    .homeMain{
        height: auto;
        padding-bottom: 5%;
    }

    .introLight{
        margin-left: 4%; 
        margin-top: 3%;
        padding: 7% 4%!important;
    }

    .introLight h1{
        margin-top: 5px;
        font-size: 3rem !important;
    }

    .introDark h1{
        margin-top: 5px;
        font-size: 3rem !important;
    }
    
    .introDark{
        margin-left: 4%; 
        margin-top: 3%;
        padding: 7% 4%!important;
    }

    .socialDiv{
        margin-left: 3%;
        width: 320px;
        max-width: 300px;
        height: 50px;
        padding-left: 5%;
    }

    .socialItem{
        width: 50px !important;
        max-width:50px !important;
        margin-right: 5% !important;
    }

    .socialItem img{
        width: 50px !important;
        height: 50px !important;
    }

    .image2{
        justify-content: center !important;
        align-items: center !important;
        text-align: center !important;
        height: 250px !important;
        width: 250px !important;
        /* border-radius: 250px; */
    }
    
    .image{
        margin-top: 0px !important;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199.99px){
    .homeMain{
        height: auto;
    }

    .introLight h3{
        font-size: 35px;
    }

    .introDark h3{
        font-size: 35px;
    }
    
    .introLight h1{
        font-size: 3.8rem;
    }

    .introDark h1{
        font-size: 3.8rem;
    }

    .image2{
        height: 350px;
        width: 350px;
    }
    .socialDiv{
        max-width: 450px;
        max-height: 100px;
    }

    .socialItem{
        width: 60px;
        max-width:60px;
        height: 60px;
        max-height: 60px;
        margin-left: 10px;
    }

    .socialItem img{
        width: 60px;
        height: 60px;
    }
}

@media only screen and (min-width: 1200px) { 
    .homeMain{
        height: auto !important;
    }

    .homeMain h2{
        font-size: 2.2rem !important;
    }

    .homeMain h1{
        font-size: 3.8rem;
    }

    .introLight{
        margin-left: 10% !important;
    }

    .introDark{
        margin-left: 10% !important;
    }
    
    .socialDiv{
        margin-left: 7% ;
    }
}