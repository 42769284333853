.flexDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.contactDark{
    color: white;
    width: auto;
    height: auto;   
    max-width: auto;
}

.contactLight{
    color: black;
    width: auto;
    height: auto;   
    max-width: auto;
}

.contactMain{
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: auto;
}

.h1 {
    margin-bottom: 10px;
    font-size: 4rem;
    line-height: 1.0625;
    font-weight: 600;
    letter-spacing: -.01em;
    text-transform: none;
}

.contactParagraph {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 28px;
    font-weight: 300;
}

.content-contactParagraphDark {
    margin-bottom: 20px;
    color: #fff;
    font-size: 1.75rem;
    line-height: 26px;
}

.content-contactParagraphLight {
    margin-bottom: 20px;
    color: black;
    font-size: 1.75rem;
    line-height: 26px;
}

.link, .link:hover{
    text-decoration: none;
    color: orange;
}

.formDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991.99px){
    .contactMain{
        height: auto;
    }

    .h1{
        font-size: 3rem;
    }

    .contactParagraph {
        margin-top: 0;
        margin-bottom: 20px;
        font-size: 25px;
        line-height: 28px;
        font-weight: 300;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199.99px){
    .contactMain{
        height: auto;
    }

    .h1{
        font-size: 3.2rem;
    }

    .contactParagraph{
        font-size: 30px !important;
        margin-top: 20px;
        margin-bottom: 20px;
        line-height: 40px;
    }
}

@media only screen and (min-width: 360px) and (max-width: 575.99px){
    .contactMain{
        height: auto;
    }

    .h1{
        font-size: 3rem !important;
    }

    .content-contactParagraphLight {
        margin-bottom: 20px;
        color: black;
        font-size: 1.3rem;
        line-height: 20px;
    }

    .content-contactParagraphDark {
        margin-bottom: 20px;
        color: white;
        font-size: 1.3rem;
        line-height: 20px;
    }
}


@media only screen and (min-width: 576px) and (max-width: 767.99px){
    .h1{
        font-size: 3rem;
    }
}