.mainBorderDark {
    width: 200px;
    height: 250px;
    max-width: 200px;
    max-height: 250px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition-duration: 0.5s;
}

.mainBorderLight {
    width: 200px;
    height: 250px;
    max-width: 200px;
    max-height: 250px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition-duration: 0.5s;
}

.mainBorderDark:hover {
    background-color: #d6d6d6;
    box-shadow: 0 0 30px rgb(115, 156, 194);
    color: black;
    transition-duration: 0.5s;
}

.mainBorderLight:hover {
    background-color: white;
    box-shadow: 0 0 30px black;
    color: black;
    transition-duration: 0.5s;
}

.mainBorderDark:hover #nameDark {
    color: black;
}

.mainBorderLight:hover #nameLight {
    color: black;
}

.mainBorderDark img {
    margin: 25px;
    margin-bottom: 15px;
    width: 150px;
    height: 150px;
    max-width: 150px;
    max-height: 150px;
    border-radius: 20px;
}

.mainBorderLight img {
    margin: 25px;
    margin-bottom: 15px;
    width: 150px;
    height: 150px;
    max-width: 150px;
    max-height: 150px;
    border-radius: 20px;
}

#nameDark {
    text-align: center;
    font-size: xx-large;
    font-weight: 500;
    text-decoration: none;
    color: azure;
}

#nameLight {
    text-align: center;
    font-size: xx-large;
    font-weight: 500;
    text-decoration: none;
    color: black;
}


@media only screen and (min-width: 360px) and (max-width: 575.99px){

    .mainBorderDark {
        width: 60px;
        height: 60px;
        max-width: 60px;
        max-height: 60px;
        border-radius: 10px;
        align-items: center;
        justify-content: center;
        text-align: center;
        opacity: 1;
        transition-duration: 0.5s;
    }

    .mainBorderLight {
        width: 60px;
        height: 60px;
        max-width: 60px;
        max-height: 60px;
        border-radius: 10px;
        align-items: center;
        justify-content: center;
        text-align: center;
        opacity: 1;
        transition-duration: 0.5s;
    }

    .mainBorderDark img {
        margin: 10px;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        max-width: 40px;
        max-height: 40px;
        border-radius: 10px;
    }

    .mainBorderLight img {
        margin: 10px;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        max-width: 40px;
        max-height: 40px;
        border-radius: 10px;
    }

    #nameLight{
        display: none;
    }

    #nameDark{
        display: none;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767.99px) {
    .mainBorderDark {
        width: 125px;
        height: 125px;
        max-width: 125px;
        max-height: 125px;
        border-radius: 10px;
        align-items: center;
        justify-content: center;
        opacity: 1;
        transition-duration: 0.5s;
    }

    .mainBorderLight {
        width: 125px;
        height: 125px;
        max-width: 125px;
        max-height: 125px;
        border-radius: 10px;
        align-items: center;
        justify-content: center;
        opacity: 1;
        transition-duration: 0.5s;
    }

    .mainBorderDark img {
        margin: 25px;
        margin-bottom: 15px;
        width: 75px;
        height: 75px;
        max-width: 75px;
        max-height: 75px;
        border-radius: 20px;
    }

    .mainBorderLight img {
        margin: 25px;
        margin-bottom: 15px;
        width: 75px;
        height: 75px;
        max-width: 75px;
        max-height: 75px;
        border-radius: 20px;
    }

    #nameDark {
        display: none;
    }

    #nameLight {
        display: none;
    }

    .mainBorderDark:hover {
        background-color: #d6d6d6;
        box-shadow: 0 0 30px rgb(115, 156, 194);
        color: black;
        transition-duration: 0.5s;
    }

    .mainBorderLight:hover {
        background-color: white;
        box-shadow: 0 0 30px black;
        color: black;
        transition-duration: 0.5s;
    }

    .mainBorderDark:hover #nameDark {
        color: black;
    }

    .mainBorderLight:hover #nameLight {
        color: black;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199.99px){
    .mainBorderLight{
        width: auto;
    }

    .mainBorderDark{
        width: auto;
    }

    .mainBorderDark img {
        margin: 25px;
        margin-bottom: 15px;
        width: 100px;
        height: 100px;
        max-width: 100px;
        max-height: 100px;
        border-radius: 20px;
    }
    
    .mainBorderLight img {
        margin: 25px;
        margin-bottom: 15px;
        width: 100px;
        height: 100px;
        max-width: 100px;
        max-height: 100px;
        border-radius: 20px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.99px){
    .mainBorderDark {
        width: 125px;
        height: 125px;
        max-width: 125px;
        max-height: 125px;
        border-radius: 10px;
        align-items: center;
        justify-content: center;
        opacity: 1;
        transition-duration: 0.5s;
    }

    .mainBorderLight {
        width: 125px;
        height: 125px;
        max-width: 125px;
        max-height: 125px;
        border-radius: 10px;
        align-items: center;
        justify-content: center;
        opacity: 1;
        transition-duration: 0.5s;
    }

    .mainBorderDark img {
        margin: 25px;
        margin-bottom: 15px;
        width: 75px;
        height: 75px;
        max-width: 75px;
        max-height: 75px;
        border-radius: 20px;
    }

    .mainBorderLight img {
        margin: 25px;
        margin-bottom: 15px;
        width: 75px;
        height: 75px;
        max-width: 75px;
        max-height: 75px;
        border-radius: 20px;
    }

    #nameDark {
        display: none;
    }

    #nameLight {
        display: none;
    }

    .mainBorderDark:hover {
        background-color: #d6d6d6;
        box-shadow: 0 0 30px rgb(115, 156, 194);
        color: black;
        transition-duration: 0.5s;
    }

    .mainBorderLight:hover {
        background-color: white;
        box-shadow: 0 0 30px black;
        color: black;
        transition-duration: 0.5s;
    }

    .mainBorderDark:hover #nameDark {
        color: black;
    }

    .mainBorderLight:hover #nameLight {
        color: black;
    }
}