.projectCardMainBorderDark{
    width: 200px;
    height: 295px;
    max-width: 200px;
    max-height: 295px;
    border-radius: 20px;
    align-items:center;
    justify-content: center;
    opacity: 1;
    transition-duration: 0.5s;
} 

.projectCardMainBorderLight{
    width: 200px;
    height: 295px;
    max-width: 200px;
    max-height: 295px;
    border-radius: 20px;
    align-items:center;
    justify-content: center;
    opacity: 1;
    transition-duration: 0.5s;
} 

.projectCardMainBorderDark:hover{
    background-color: #d6d6d6;
    box-shadow: 0 0 10px rgb(115, 156, 194);
    color: black ;
    transition-duration: 0.5s;
}

.projectCardMainBorderLight:hover{
    background-color: white;
    box-shadow: 0 0 10px gray;
    color: black ;
    transition-duration: 0.5s;
}

.projectCardMainBorderDark:hover #projectCardMainBorderNameDark{
    color: black;
}

.projectCardMainBorderDark:hover #projectStatusDark{
    color: black;
}

.projectCardMainBorderLight:hover #projectCardMainBorderNameLight{
    color: black;
}

.projectCardMainBorderLight:hover #projectStatusLight{
    color: black;
}

.projectCardMainBorderDark img{
    margin: 25px;
    margin-bottom: 15px;
    width: 150px;
    height: 150px;
    max-width: 150px;
    max-height: 150px;
    border-radius: 20px;
}

.projectCardMainBorderLight img{
    margin: 25px;
    margin-bottom: 15px;
    width: 150px;
    height: 150px;
    max-width: 150px;
    max-height: 150px;
    border-radius: 20px;
}

#projectCardMainBorderNameDark{
    text-align: center;
    font-size: xx-large;
    font-weight: 500;
    text-decoration: none;
    color: azure; 
}

#projectCardMainBorderNameLight{
    text-align: center;
    font-size: xx-large;
    font-weight: 500;
    text-decoration: none;
    color: black; 
}

#projectStatusDark{
    text-align: center;
    text-decoration: none;
    color: azure;
}

#projectStatusLight{
    text-align: center;
    text-decoration: none;
    color: black; 
}

@media only screen and (min-width: 576px) and (max-width: 767.99px) {

    .projectCardMainBorderDark{
        width: 150px;
        height: 245px;
        max-width: 150px;
        max-height: 245px;
        border-radius: 20px;
        align-items:center;
        justify-content: center;
        opacity: 1;
        transition-duration: 0.5s;
    } 
    
    .projectCardMainBorderLight{
        width: 150px;
        height: 245px;
        max-width: 150px;
        max-height: 245px;
        border-radius: 20px;
        align-items:center;
        justify-content: center;
        opacity: 1;
        transition-duration: 0.5s;
    }

    .projectCardMainBorderDark img{
        margin: 25px;
        margin-bottom: 15px;
        width: 100px;
        height: 100px;
        max-width: 100px;
        max-height: 100px;
        border-radius: 20px;
    }
    
    .projectCardMainBorderLight img{
        margin: 25px;
        margin-bottom: 15px;
        width: 100px;
        height: 100px;
        max-width: 100px;
        max-height: 100px;
        border-radius: 20px;
    }

    #projectCardMainBorderNameDark{
        text-align: center;
        font-size: x-large;
        font-weight: 500;
        text-decoration: none;
        color: azure; 
    }
    
    #projectCardMainBorderNameLight{
        text-align: center;
        font-size: x-large;
        font-weight: 500;
        text-decoration: none;
        color: black; 
    }

    #projectStatusDark{
        display: none;
        text-align: center;
        text-decoration: none;
        color: azure;
    }
    
    #projectStatusLight{
        display: none;
        text-align: center;
        text-decoration: none;
        color: black; 
    }
}

@media only screen and (min-width: 360px) and (max-width: 575.99px){

    .projectCardMainBorderDark{
        width: 120px;
        height: 150px;
        max-width: 150px;
        max-height: 245px;
        border-radius: 20px;
        align-items:center;
        justify-content: center;
        opacity: 1;
        transition-duration: 0.5s;
    } 
    
    .projectCardMainBorderLight{
        width: 120px;
        height: 150px;
        max-width: 150px;
        max-height: 245px;
        border-radius: 20px;
        align-items:center;
        justify-content: center;
        opacity: 1;
        transition-duration: 0.5s;
    }

    .projectCardMainBorderDark img{
        margin: 26px;
        margin-bottom: 8px;
        width: 66px;
        height: 66px;
        max-width: 66px;
        max-height: 66px;
        border-radius: 20px;
    }
    
    .projectCardMainBorderLight img{
        margin: 26px;
        margin-bottom: 8px;
        width: 66px;
        height: 66px;
        max-width: 66px;
        max-height: 66px;
    }

    #projectCardMainBorderNameDark{
        text-align: center;
        font-size: 1.1rem;
        font-weight: 500;
        text-decoration: none;
        color: azure; 
    }
    
    #projectCardMainBorderNameLight{
        font-size: 1.1rem;
    }

    #projectStatusDark{
        display: none !important;
    }
    
    #projectStatusLight{
        display: none !important;
    }
}