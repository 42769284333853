/* .navbar{
    float: right;
    position: sticky;
    top: 0;
    -webkit-position:sticky
} */

.font{
    font-size: 17px;
}

/* .font:active{    	
    color: orange;
} */