.techstackDark{
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 30px;
}

.techstackLight{
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 30px;
}

.loadingDark{
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 20px;
}

.loadingLight{
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 20px;
}

.techmain{
    height: auto;
    padding: 0;
    margin: 0;
    padding-top: 30px;
    margin-bottom: 100px;
}

.card{
    justify-content: center;
    justify-items: center;
    text-align: center;
}

.h1 {
    margin-bottom: 10px;
    font-size: 4rem;
    line-height: 1.0625;
    font-weight: 600;
    letter-spacing: -.01em;
    text-transform: none;
}

@media only screen and (min-width: 360px) and (max-width: 575.99px){
    .techmain{
        height: auto;
        margin-bottom: 0px;
        padding-bottom: 30px;
    }

    .techstackDark{
        margin-bottom: 0px !important;
    }

    .techstackLight{
        margin-bottom: 0px !important;
    }

    .techstackDark h1{
        font-size: 2.2rem;
    }

    .techstackLight h1{
        font-size: 2.2rem;
    }

    .loadingLight{
        margin-left: 3%;
        margin-right: 3%;
        margin-top: 0px;
    }

    .loadingLight h3{
        font-size: large;
        font-weight: 400;
    }

    .loadingDark{
        margin-left: 3%;
        margin-right: 3%;
        margin-top: 0px;
    }

    .loadingDark h3{
        font-size: large;
        font-weight: 400;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767.99px){
    .techmain{
        height: auto;
        padding-top: 0px;
        margin-bottom: 0px;
        padding-bottom: 30px;
    }
    .techstackDark h1{
        font-size: 3rem;
    }

    .techstackLight h1{
        font-size: 3rem;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.99px){
    .techmain{
        margin-bottom: 50px;
    }

    .techmain h1{
        font-size: 3rem !important;
    }
}

@media only screen and (min-width: 992px){
    .techmain h1{
        font-size: 3.2rem !important;
    }

    .techmain{
        height: auto;
        margin-bottom: 0px;
        padding-bottom: 30px;
    }
}